import CustomerLocationList from "./list";
import CustomerLocationShow from "./show";


if (document.body.getAttribute("data-current-path") === "customer/locations") {
    const action = document.body.getAttribute("data-current-action")
    if (action === "index") {
        new CustomerLocationList();
    } else if(action === "show") {
        new CustomerLocationShow();
    }
}